exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artist-js": () => import("./../../../src/pages/artist.js" /* webpackChunkName: "component---src-pages-artist-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maurice-js": () => import("./../../../src/pages/maurice.js" /* webpackChunkName: "component---src-pages-maurice-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-pages-works-mixed-media-js": () => import("./../../../src/pages/works/mixed-media.js" /* webpackChunkName: "component---src-pages-works-mixed-media-js" */),
  "component---src-pages-works-paintings-js": () => import("./../../../src/pages/works/paintings.js" /* webpackChunkName: "component---src-pages-works-paintings-js" */),
  "component---src-pages-works-video-js": () => import("./../../../src/pages/works/video.js" /* webpackChunkName: "component---src-pages-works-video-js" */),
  "component---src-templates-single-review-js": () => import("./../../../src/templates/single-review.js" /* webpackChunkName: "component---src-templates-single-review-js" */)
}

